import React, { useRef, useState, useEffect } from "react";
import { AccordionWrapper } from "../styles";
import { NewRegistratonData } from "../helper";
import ModalTemp from "share/templates/ModalTemp";
import RegistrationTemp from "share/templates/RegistrationTemp";
import { useHistory } from "react-router-dom";
import Accordion from "share/organisms/accordion";
import PhaseSelection from "./phase-selection";
import UploadModal, { UploadErrorModal } from "./upload-model";
import { saveAs } from "file-saver";
import { getErrorList } from "utils/utility/school";
import { useDispatch, useSelector } from "react-redux";
import { CardWrapper,CardItem, CustomButton,Instructions,ModalHeader,} from "./styles";
import { bulkRegistrationExcelUploadRequest as uploadExcel, examPhaseListRequest, getPendingRegistrationsRequest} from "../actions";

const NewRegistration = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [uploadPercentage, setUploadPercentage] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showDownload, setShowDownload] = useState(false);
  const excelUploader = useRef(null);
  const excelRegisteredData = useSelector(({ newRegistration }) => newRegistration.excelRegisteredData);
  const phaseListData = useSelector(  ({ newRegistration }) => newRegistration && newRegistration.examPhaseListReducer );
  const { data: registedStudentsList, status } = excelRegisteredData.data;
  let data = [
    {
      title: "Exam Dates/Timings",
      desc: "Please select your students availability for each Individual Exam. Each exam has two options for your school to choose from.",
      body: <PhaseSelection />,
    },
  ];

  useEffect(() => {
    var interval = null;
    if (excelRegisteredData.isLoading) {
      let count = 4;
      interval = setInterval(() => {
        count = count + 3;
        if (count <= 100) setUploadPercentage(count);
        if (count === 100) {
          clearInterval(interval);
        }
      }, 190);
    }
    return () => {
      clearInterval(interval);
    };
  }, [excelRegisteredData.isLoading]);

  useEffect(() => {
    dispatch(examPhaseListRequest());
  }, []);// eslint-disable-line react-hooks/exhaustive-deps

  const openExcelDailog = () => {
    excelUploader.current.click();
  };

  const upload = ({ target: { files } }) => {
    setUploadPercentage(1);
    let data = new FormData();
    data.append("file", files[0]);
    const config = {
      onUploadProgress: (event) => {
        // setUploadPercentage(percentCompleted);
      },
    };
    let payload = {
      data,
      successcallback: (res) => {
        setShowSuccessModal(true);
        setUploadPercentage(100);
        history.replace({
          pathname: `/school/new-registration/summary?groupId=${res.data.group_id}`,
        });
      },
      errorcallback: (errors) => {
        setUploadPercentage(false);
        setShowErrorModal(errors);
      },
      config,
    };
    dispatch(uploadExcel(payload));
  };

  const handleErrorModalClose = () => setShowErrorModal(false);

  const handleUploadModalClose = () => {
    setUploadPercentage(false);
    if (status === "success") {
      history.replace({
        pathname: `/school/new-registration/summary?groupId=${registedStudentsList.group_id}`,
      });
    }
  };

  /**
 * this function  make sure to  navigates to the defined path or any register click
 * @param {Object} item
 * @returns {void} 
 */

  const handleClick = (item) => {
    sessionStorage.setItem("feature", item.feature);
    if (item.path && item.feature !== "pending") {
      history.push({ pathname: item.path });
    } else if (item.feature === "pending")
      dispatch(getPendingRegistrationsRequest(item.path));
    else openExcelDailog();
  };


  const getAccordTitleDesc = () => {
    if (phaseListData.data && !phaseListData.data.is_edit)
      return [
        {
          ...data[0],
          desc: "Student Registrations will be open until 3 days before the exam. Any registrations after that will not be considered.",
        },
      ];
    return data;
  };

  const handleDownload = () => {
    setShowDownload(!showDownload);
  };

  const downloadExcelTemplate = async () => {
    let fileName = `registrations-template.xlsx`;
    // saveAs(
    //   "https://cnaps.s3.ap-south-1.amazonaws.com/bulk-upload/cnaps-bulk-upload-sample-format_new.xlsx",
    //   fileName
    // );
    saveAs(
      "https://downloads.cnaps.co.in/sample-files/2024-25/bulk-upload/cnaps-bulk-upload-sample-format_2022-2023.xlsx",
      fileName
    );
    setShowDownload(false);
  };

  return (
    <RegistrationTemp headingText={"New Registrations"}>
      {/* <AccordionWrapper>
        <Accordion data={getAccordTitleDesc()} variant="descriptive" />
      </AccordionWrapper> */}
      <input
        type="file"
        accept=".xlsx"
        id="file"
        onClick={(event) => {
          event.target.value = null;
        }}
        data-testid='upload-excel-sheet'
        ref={excelUploader}
        style={{ display: "none" }}
        onChange={upload}
      />
      {showDownload && (
        <ModalTemp
          primaryBtn="Download"
          onCancel={handleDownload}
          onOk={downloadExcelTemplate}
          data-testid='download-excel-sheet'
        >
          <ModalHeader>
            <h3>Excel Template</h3>
          </ModalHeader>
          <Instructions>
            <li>Student’s name should be filled in the ‘Name’ column</li>
            <li>Student’s Surname is compulsory</li>
            <li>Class column should contain numbers 1 to 10</li>
            <li>Gender can be either male or female</li>
            <li>
              The Exam fields ISMO, ISSO, ISKO, ISEO, ISCO, ROM, NTEX and JOSA should be filled
              with ‘Yes’ if the student wants to take the exam.
            </li>
            <li>Student's school Roll number/admission number should be entered in the roll number column</li>
          </Instructions>
        </ModalTemp>
      )}
       {/* to open uploadmodal when uploadpercentage or showsuccessmodal and excelregistereddata has no error */}
      {((uploadPercentage && !showErrorModal) ||
        (showSuccessModal && !showErrorModal)) &&
        !excelRegisteredData.error && (
          <UploadModal
            progress={uploadPercentage}
            onClose={handleUploadModalClose}
            data-testid='upload-modal-cloase'
          />
        )}
      {showErrorModal && (
        <UploadErrorModal
          errorList={getErrorList(showErrorModal)}
          onClose={handleErrorModalClose}
          data-testid='error-modal-close'
        />
      )}
      <CardWrapper className="row">
        {NewRegistratonData.map((item, index) => (
          <div className="new-reg-col-wrap col-xl-4">
            <CardItem key={index} 
            // opacity={phaseListData.data.is_edit}
            >
              <img src={item.iconName} alt="icon" className="icon" />
              <p className="card-text">{item.headingText}</p>
              <CustomButton
                width={item.width}
                onClick={() => handleClick(item)}
                data-testid={`new-registration-data-${index}`}
                // disabled={phaseListData.data.is_edit}
                disabled={item.disabled}
              >
                {item.buttonText}
              </CustomButton>
              <p className="description">
                {item.hasDownloadText && (
                  <a onClick={handleDownload} className="link" data-testid='has-download-text'>
                    Download
                  </a>
                )}{" "}
                {item.descriptionText}
              </p>
            </CardItem>
          </div>
        ))}
      </CardWrapper>
    </RegistrationTemp>
  );
};

export default NewRegistration;
