export const studentFormFields = {
  name: "",
  surname: "",
  class: [],
  gender: [],
  exams: [],
  rollnumber: "",
};

export const removeStrToClass = (cls) => {
  let cl = cls;
  if (cls.includes("st")) cl = cls.replace("st", "");
  else if (cls.includes("nd")) cl = cls.replace("nd", "");
  else if (cls.includes("rd")) cl = cls.replace("rd", "");
  else if (cls.includes("th")) cl = cls.replace("th", "");
  else cl = cls;
  return parseInt(cl);
};

export const isDisabled = (formsList) => {
  if (!formsList) return false;
  return formsList.every(
    (data) =>
      data.name &&
      data.surname &&
      data.class.length &&
      data.gender.length &&
      data.exams.length &&
      data.rollnumber &&
      data.mobile_number
  );
};

export const classNames = [
  { value: 1, label: "class 1" },
  { value: 2, label: "class 2" },
  { value: 3, label: "class 3" },
  { value: 4, label: "class 4" },
  { value: 5, label: "class 5" },
  { value: 6, label: "class 6" },
  { value: 7, label: "class 7" },
  { value: 8, label: "class 8" },
  { value: 9, label: "class 9" },
  { value: 10, label: "class 10" },
];

export const genderNames = [
  { value: "male", label: "Male" },
  { value: "female", label: "Female" },
];

export const exams = [
  {
    value: "ISMO",
    label: "Maths Olympaid",
  },
  {
    value: "ISSO",
    label: "Science Olympaid",
  },
  {
    value: "ISCO",
    label: "Computer Olympaid",
  },
  {
    value: "ISEO",
    label: "English Olympaid",
  },
];

export const examPhases = [
  {
    phase_list: [
      {
        exam_id: "ISMO",
        exam_abr: "Mathematical Olympaid",
        phase_id: "phase1",
        is_selected: false,
      },
    ],
  },

  {
    phase_list: [
      {
        exam_id: "ISSO",
        exam_abr: "Science Olympaid",
        phase_id: "phase1",
        is_selected: false,
      },
    ],
  },

  {
    phase_list: [
      {
        exam_id: "ISKO",
        exam_abr: "Knowledge Olympaid",
        phase_id: "phase1",
        is_selected: false,
      },
    ],
  },

  {
    phase_list: [
      {
        exam_id: "ISEO",
        exam_abr: "English Olympaid",
        phase_id: "phase1",
        is_selected: false,
      },
    ],
  },

  {
    phase_list: [
      {
        exam_id: "ISCO",
        exam_abr: "Computer Olympaid",
        phase_id: "phase1",
        is_selected: false,
      },
    ],
  },
];

export const examsAbr = {
  ISMO: "Mathematical Olympaid",
  ISKO: "Knowledge Olympaid",
  ISSO: "Science Olympaid",
  ISCO: "Computer Olympaid",
  ISEO: "English Olympaid",
};
