import React from "react";
import Moment from "moment";
import notify from "utils/notifications";
import { EXAM_TYPES } from "utils/utility/test-engine";
import { FiFileText } from "react-icons/fi";
import { calculateTimeLeft } from "./utils";
import { saveAs } from "file-saver";
import {
  Wrapper,
  Main,
  Header,
  Logo,
  Exam,
  VLine,
  HLine,
  ButtonGrid,
  StrokeButton,
  StyledButton,
  Hint,
  Timer,
  Badge,
} from "./styles";
import { getValues, isEmptyObj } from "utils/utility/obj-arr";
import DownloadIcon from "assets/download-icon.svg";
import LevelTwoBadge from "assets/level_two_badge.svg";

function ExamDetailsCard(props) {
  const { exam, handleTest, handlePdf, isLevelTwo, getExams } = props;
  const getRequiredPdfData = (type, idx) => {
    const data = getLinkAndTitle(exam, type, idx);
    if (data && data.link) handlePdf(data.link, data.title);
    else notify("info", "The content will be Updated soon");
  };

  const handleMockTest = () => {
    if (exam?.mock_test?.is_available) handleTest(exam, EXAM_TYPES.MOCK_TEST);
    else notify("info", "The content will be Updated soon");
  };
  const downloadCertificate = async (certificate_url) => {
    let fileName = `certificate.pdf`;
    saveAs(certificate_url, fileName);
  };

  if (exam)
    return (
      <Wrapper>
        <Logo>
          <img src={exam.exam_model_logo} alt="" />
        </Logo>
        <Main>
          <Header>{exam.exam_name}</Header>
          {isLevelTwo && (
            <Badge bgImage={LevelTwoBadge}>
              <span class="primary">You reached </span>
              <span class="secondary">LEVEL 2</span>
            </Badge>
          )}
          <div className="exam-details-wrap">
            <Exam>
              <div className="exam">
                <div className="examInfo">Exam Date</div>
                <div className="examDetails">
                  {Moment(exam.exam_date).format("DD-MMM-YYYY")}
                </div>
              </div>
              <VLine />
              <div className="exam">
                <div className="examInfo">Exam Start - End Time</div>
                <div className="examDetails">
                  {Moment(exam.start_time).format("HH:mm A")}&nbsp; - &nbsp;
                  {Moment(exam.end_time).format("HH:mm A")}
                </div>
              </div>
              <VLine />
              <div className="exam">
                <div className="examInfo">Duration</div>
                <div className="examDetails">{exam.duration} Mins</div>
              </div>
              <VLine />
              <div className="exam">
                <div className="examInfo">Questions</div>
                <div className="examDetails">{exam.total_questions}</div>
              </div>
              <VLine />
              <div className="exam">
                <div className="examInfo">Marks</div>
                <div className="examDetails">{exam.max_marks}</div>
              </div>
            </Exam>
            <HLine />
            <Exam style={{ justifyContent: isLevelTwo && "space-evenly" }}>
              {!isLevelTwo && (
                <>
                  <div className="exam">
                    <div className="examInfo">Model papers</div>
                    <div style={{ display: "flex" }}>
                      {exam.model_paper_list &&
                        exam.model_paper_list.map((paper, idx) => (
                          <div
                            className="examDetails"
                            data-testid={`model-paper-${idx}`}
                            onClick={() =>
                              getRequiredPdfData("model_paper", idx)
                            }
                          >
                            <span className="pdf-icon">
                              <FiFileText />
                            </span>
                            Paper {idx + 1}
                          </div>
                        ))}
                    </div>
                  </div>
                  {exam.mock_test.mock_paper_url && (
                    <>
                      <VLine />
                      <div className="exam">
                        <div className="examInfo">
                          Previous Year Sample Paper
                        </div>
                        <div
                          data-testid="previous-year-exam-paper"
                          onClick={() => getRequiredPdfData("sample")}
                          className="examDetails"
                        >
                          <span className="pdf-icon">
                            <FiFileText />
                          </span>
                          PDF Paper
                        </div>
                      </div>
                    </>
                  )}
                  <VLine />
                </>
              )}
              <div className="exam">
                <div className="examInfo">Exam Pattern</div>
                <div
                  onClick={() => getRequiredPdfData("pattern")}
                  className="examDetails"
                >
                  <span className="download-icon">
                    {/* <FiFileText /> */}
                    <img src={DownloadIcon} alt="download" />
                  </span>
                  View PDF
                </div>
              </div>
              <VLine style={{ marginRight: isLevelTwo && "25px" }} />
              <div className="exam">
                <div className="examInfo">Exam Syllabus</div>
                <div
                  data-testid="exam-syllabus"
                  onClick={() => getRequiredPdfData("syllabus")}
                  className="examDetails"
                >
                  <span className="download-icon">
                    {/* <FiFileText /> */}
                    <img src={DownloadIcon} alt="download" />
                  </span>
                  View PDF
                </div>
              </div>
            </Exam>
          </div>
          <ButtonGrid>
            <StrokeButton
              className="strk-btn"
              data-testid="start-practice-test"
              onClick={handleMockTest}
            >
              Start Practice Mock Test
            </StrokeButton>
            {/* {exam.paper_status === "expired" ? (
              <ReviseButton onClick={handleReviseTest}>
                Revise Missed Test
              </ReviseButton>
            ) : ( */}
            <div>
              {exam.certificate_details?.is_certificate_available && (
                <button
                  className="button-certificate"
                  onClick={() =>
                    downloadCertificate(
                      exam.certificate_details.certificate_url
                    )
                  }
                >
                  Certificate
                </button>
              )}
              <StyledButton
                paperStatus={exam.paper_status}
                data-testid="exam-paper-stats"
                onClick={() => handleTest(exam, exam.exam_category)}
              >
                {getButtonText(exam, getExams)}
              </StyledButton>
            </div>
            {/* )} */}
          </ButtonGrid>
        </Main>
      </Wrapper>
    );
  else return null;
}

const getLinkAndTitle = (exam, type, index = 0) => {
  if (type === "sample")
    return {
      title: "Previous Year Sample Paper",
      link: exam.mock_test.mock_paper_url,
    };
  else if (type === "model_paper")
    return {
      title: "Model Test Paper",
      link: exam.model_paper_list ? exam.model_paper_list[index] : null,
    };
  else if (type === "pattern")
    return { title: "Exam Pattern", link: exam.exam_pattern_url };
  else if (type === "syllabus")
    return { title: "Exam Syllabus", link: exam.exam_syllabus_url };
};

const CountDown = ({ exam, getExams }) => {
  const [timeLeft, setTimeLeft] = React.useState(
    calculateTimeLeft(exam.time_left)
  );

  React.useEffect(() => {
    const id = setTimeout(() => {
      setTimeLeft(calculateTimeLeft(timeLeft.ms || exam.time_left));
    }, 1000);

    return () => {
      clearTimeout(id);
    };
  });
  const obj = { ...timeLeft };
  if (!isEmptyObj(obj)) delete obj.ms;
  const timerComponents = !isEmptyObj(obj) ? getValues(obj).join(" : ") : "";

  if (!parseInt(timeLeft.ms || "0") || timeLeft.ms < 0) getExams();

  return (
    <>
      Take Test on
      <Timer>{timerComponents.length ? timerComponents : null}</Timer>
    </>
  );
};

const getButtonText = (exam, getExams) => {
  switch (exam.paper_status) {
    case "in_progress":
      if (exam.exam_category === EXAM_TYPES.MISSED_TEST)
        return "Resume Missed Test";
      return "Resume Test";
    case "submitted":
      if (exam.exam_category === EXAM_TYPES.MISSED_TEST)
        return "View Missed Test Result";
      return "View Result";
    case "started":
      return "Take Test";
    case "expired":
      return "Attempt Missed Test";
    default: {
      if (exam.days_left === 0)
        return (
          <span>
            <CountDown exam={exam} getExams={getExams} />
          </span>
        );
      return `Take Test on ${Moment(exam.exam_date).format("DD-MMM-YYYY")}`;
    }
  }
};

export default ExamDetailsCard;
